import {useContext, useState} from "react";
import {ModalContext} from "../components/Modal/Modal.context";

const useModal = (data) => {
  const [slideNumber, setSlideNumber] = useState(0);
  const { modalOpen, setModalOpen } = useContext(ModalContext);

  const handleOpenModal = (index) => {
    setSlideNumber(index);
    setModalOpen(true);
  };

  // Close Modal
  const handleCloseModal = () => {
    setModalOpen(false);
  };



  // Previous Image
  const prevSlide = () => {
    slideNumber === 0
      ? setSlideNumber(data.length - 1)
      : setSlideNumber(slideNumber - 1);
  };

  // Next Image
  const nextSlide = () => {
    slideNumber + 1 === data.length
      ? setSlideNumber(0)
      : setSlideNumber(slideNumber + 1);
  };

  return {
    handleOpenModal,
    handleCloseModal,
    prevSlide,
    nextSlide,
    modalOpen,
    slideNumber,
  };
};

export default useModal;
