import React, { forwardRef, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Gallery from "../Gallery/Gallery";
import About from "../About/About";
import Contact from "../Contact/Contact";
import { animateScroll as scroll } from "react-scroll";

import { AnimatePresence } from "framer-motion";
import Videography from "../Videography";
import Photography from "../Photography";
import { useWindowSize } from "../../hooks/useWindowSize";
import { DeviceSizes } from "../../constants/sizes";

const AnimatedRoutes = forwardRef((props, ref) => {
  const location = useLocation();

  useEffect(() => {
    scroll.scrollToTop({ smooth: true });
  }, [location]);

  const [deviceWidth] = useWindowSize();

  const isDesktop = deviceWidth >= DeviceSizes.laptop;

  const home = location.pathname === "/";

  useEffect(() => {
    if (isDesktop && home) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [isDesktop, home]);

  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname} ref={ref}>
        <Route path="/" element={<Photography />} />
        <Route path="/:id" element={<Gallery />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/videography" element={<Videography />} />
      </Routes>
    </AnimatePresence>
  );
});

export default AnimatedRoutes;
