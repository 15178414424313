import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './index.scss';

export const GridCell = ({ children, sm, md, lg, xl, offsetClassName, className }) => {
    const gridCellClassName = cx('grid__cell',
        offsetClassName && [offsetClassName],
        `grid__cell--sm-${sm}`,
        `grid__cell--md-${md}`,
        `grid__cell--lg-${lg}`,
        `grid__cell--xl-${xl}`,
        className && [className]);

    return (
        <div className={gridCellClassName}>
            {children}
        </div>
    );
};

GridCell.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any,
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number,
    offsetClassName: PropTypes.string,
};

GridCell.defaultProps = {
    className: '',
    children: null,
    sm: 4,
    md: 6,
    lg: 12,
    xl: 13,
    offsetClassName: '',
};

export const Grid = ({ className, children }) => {
    const gridClassName = cx('grid', {
        [className]: className,
    });

    return (
        <div className={gridClassName}>
            {children}
        </div>
    );
};

Grid.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any.isRequired,
};

Grid.defaultProps = {
    className: '',
};
