import React, { useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { WindowManager } from "react-window-decorators";

import Container from "./components/Container";
import GridOverlay from "./components/GridOverlay";
import breakpoints from "./constants/breakpoints";
import { useVHProvider } from "./hooks/useVhProvider";
import Header from "./components/Header";

import { useLockBodyScroll } from "./hooks/useLockBodyScroll";
import AnimatedRoutes from "./components/AnimatedRoutes";
import Footer from "./components/Footer/Footer";
import { ModalContext } from "./components/Modal/Modal.context";
import "./scss/base/normalize.scss";
import "./scss/base/app.scss";

export const HeaderContext = React.createContext(null);

// eslint-disable-next-line no-new
new WindowManager(breakpoints.formatted, 100);

const App = () => {
  useVHProvider();

  const [modalOpen, setModalOpen] = useState(false);
  const [isOpenHeader, setIsOpenHeader] = useState(false);

  useLockBodyScroll(modalOpen || isOpenHeader);

  return (
    <Router>
      <div className="wrapper">
        <HeaderContext.Provider value={{ isOpenHeader, setIsOpenHeader }}>
          <Header hide={modalOpen} />
        </HeaderContext.Provider>
        <Container>
          <GridOverlay />
          <ModalContext.Provider value={{ modalOpen, setModalOpen }}>
            <AnimatedRoutes />
          </ModalContext.Provider>
        </Container>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
