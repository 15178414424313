import { useEffect } from "react";
import { BehaviorSubject, debounceTime, fromEvent } from "rxjs";

// Use this in components
const resize = new BehaviorSubject({
  height: window.innerHeight,
  width: window.innerWidth,
});

// Call this only once in APP
export const initResizeListener = () => {
  fromEvent(window, "resize")
    .pipe(debounceTime(100))
    .subscribe(() =>
      resize.next({
        height: window.innerHeight,
        width: window.innerWidth,
      })
    );
};

export const useVHProvider = () => {
  useEffect(() => {
    const r = resize.subscribe({
      next: (size) =>
        document.documentElement.style.setProperty(
          "--vh",
          `${size.height * 0.01}px`
        ),
    });
    return () => r.unsubscribe();
  }, []);
};
