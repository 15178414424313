import { motion } from "framer-motion";
import styles from "./ModalAside.module.scss";
import { NavLink } from "react-router-dom";
import { routes } from "../../constants/routes";
import { DeviceSizes } from "../../constants/sizes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {useMemo} from "react";
import { useWindowSize } from "../../hooks/useWindowSize";
import {
  faBehanceSquare,
  faInstagramSquare,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import cx from "classnames";

export const ForwardRef = React.forwardRef((props, modalRef) => {
  const { isOpen, onClose, setIsOpenHeader } = props;

  return (
    <AsideContent
      modalRef={modalRef}
      isOpen={isOpen}
      onClose={onClose}
      setIsOpenHeader={setIsOpenHeader}
    />
  );
});

const AsideContent = ({ isOpen, onClose, modalRef }) => {
  const [deviceWidth, deviceHeight ] = useWindowSize();

  const navLinks = [
    { title: "PHOTOGRAPHY", to: routes.START_PAGE },
    { title: "VIDEOGRAPHY", to: routes.PROJECTS_PAGE },
    { title: "ABOUT", to: routes.ABOUT_PAGE },
    { title: "CONTACT", to: routes.CONTACT_PAGE },
  ];

  const socialMedia = [
    {
      icon: faInstagramSquare,
      link: "https://www.instagram.com/predrag9/?hl=en",
    },
    {
      icon: faLinkedin,
      link: "https://www.linkedin.com/in/predragtodorovic1/",
    },
    {
      icon: faBehanceSquare,
      link: "https://www.behance.net/predragt",
    },
  ];

  const getConfig = (initialDelay, index) => {
    const animationDelay = initialDelay + index / 10;
    const exitAnimationDelay = index / 10;

    const animationConfig = {
      initial: { opacity: 0, translateX: 50 },
      animate: {
        opacity: 1,
        translateX: 0,
        transition: { duration: 0.5, delay: animationDelay },
      },
      exit: {
        opacity: 0,
        translateX: 50,
        transition: { duration: 0.5, delay: exitAnimationDelay },
      },
    };

    return animationConfig;
  };

  const isLandscape = useMemo(() => {
    return deviceHeight < 500;
  }, [deviceHeight]);

  return (
    <motion.div
      initial={{ x: "100%", opacity: 0 }}
      animate={{ x: "30%", opacity: 1 }}
      exit={{
        x: "100%",
      }}
      transition={{
        type: "spring",
        stiffness: 260,
        damping: 20,
      }}
      className={styles.modal}
      ref={modalRef}
    >
      <div className={cx(styles.menuItems, {[styles.isLandscape]: isLandscape } )}>
        {isOpen &&
          navLinks.map(({ title, to }, index) => (
            <motion.div
              key={title}
              {...getConfig(0.5, index)}
              className={styles.menuItem}
            >
              <NavLink
                to={to}
                key={index}
                end={to === routes.START_PAGE}
                onClick={onClose}
                style={({ isActive }) =>
                  isActive
                    ? {
                        fontWeight: "bold",
                      }
                    : null
                }
              >
                {title}
              </NavLink>
            </motion.div>
          ))}
        <div className={styles.social}>
          {socialMedia.map((media, index) => (
            <motion.div key={media.link} {...getConfig(0.5, index)}>
              <a
                className={styles.mediaLink}
                href={media.link}
                target={deviceWidth > DeviceSizes.laptop ? "_blank" : "_self"}
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={media.icon} />
              </a>
            </motion.div>
          ))}
        </div>
      </div>
    </motion.div>
  );
};

export default AsideContent;
