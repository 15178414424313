import React from "react";

import styles from "./About.module.scss";

import { motion, AnimatePresence } from "framer-motion";

import pt from "../../assets/images/predrag todorovic.jpg";

const aboutText = [
  {
    text: `I'm Predrag Todorović, Belgrade based photographer and filmmaker. Photography has been a big part of my life for a decade and a half, and the skill of filmmaking I've been crafting for almost ten years now.`,
  },
  {
    text: `Got my bachelors degree in digital arts and my masters degree in photography from the Faculty for media and communications.`,
  },
  {
    text: `My work has been published in two solo exhibitions: "Jazz - Emotion of improvisation" (2017) and "Jazz from the south" (2014), and numerous group exhibitions.`,
  },
  {
    text: `Always fascinated with music (mostly jazz and blues), my inspiration comes from so many different areas, like travel, new technologies, eccentric personalities.`,
  },
];

const textWithLink = () => {
  return (
    <p className={styles.text}>
      I'm also doing a lot of corporate filmmaking and photography for companies
      and international clients, as I am CEO and founder of Diptych production
      <a
        href="https://diptych.rs"
        target="_blank"
        rel="noopener noreferrer"
        className={styles.link}
      >
        {""}
        (www.diptych.rs).
        {""}
      </a>
      Occasionally, you can find me lecturing in fields of commercial
      photography.
    </p>
  );
};

const About = () => {
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 1 } }}
        exit={{ opacity: 0 }}
        className={styles.root}
      >
        <img
          src={pt}
          alt="Predrag Todorovic"
          className={styles.imageWrapper}
          height="100%"
          width="100%"
        />
        <div className={styles.textRoot}>
          <div className={styles.textWrapper}>
            <h1 className={styles.headline}>
              <span className={styles.spanOne}>MEET</span>
              <span className={styles.spanTwo}>PREDRAG</span>
            </h1>
            {aboutText.map((item, index) => (
              <React.Fragment key={index}>
                <p key={index} className={styles.text}>
                  {item.text}
                </p>
                {index === 3 && textWithLink()}
              </React.Fragment>
            ))}
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default About;
