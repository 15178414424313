import React, { Fragment, useEffect, useRef, useState } from "react";
import { PT_SECTIONS_MOCK } from "mock/Photos.mock";
import Card from "../Card";
import { AnimatePresence, motion } from "framer-motion";

import styles from "./Photography.module.scss";
import { DeviceSizes } from "../../constants/sizes";
import { useWindowSize } from "../../hooks/useWindowSize";
import { useLocation } from "react-router-dom";

const Main = () => {
  const [albums, setAlbums] = React.useState([]);

  useEffect(() => {
    setAlbums(PT_SECTIONS_MOCK.sections);
  }, []);

  let wrapperRef = useRef(null);

  const handleScrollWheel = (e) => {
    const wrapperScrollPosition = wrapperRef.scrollLeft;
    wrapperRef.scrollTo({
      top: 0,
      left: wrapperScrollPosition + e.deltaY,
      behaviour: "smooth",
    });
  };

  const handleCardClick = (e) => {
    const wrapperScrollPosition = wrapperRef.scrollLeft;
    localStorage.setItem("scrollPosition", wrapperScrollPosition);
  };

  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const position = localStorage.getItem("scrollPosition");
    if (position) {
      setScrollPosition(position);
    }
  }, []);

  useEffect(() => {
    if (scrollPosition) {
      wrapperRef.scrollTo({
        left: scrollPosition,
        behavior: "auto",
      });
    }
  }, [scrollPosition]);

  return (
    <AnimatePresence>
      <motion.div
        className={styles.root}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 1 } }}
        exit={{ opacity: 0 }}
      >
        <div
          className={styles.wrapper}
          id="section"
          ref={(node) => (wrapperRef = node)}
          onWheel={(e) => handleScrollWheel(e)}
        >
          {albums.map((album, index) => (
            <Fragment key={index}>
              <Card
                title={album.title}
                classNames={styles.card}
                wrapperType="link"
                image={{
                  src: album.cover,
                  alt: album.title,
                }}
                href={`/${album.type}`}
                onClick={handleCardClick}
              />
            </Fragment>
          ))}
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default Main;
