import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { withWindow } from 'react-window-decorators';
import { Grid, GridCell } from '../Grid';
import breakpoints from '../../constants/breakpoints';

import './index.scss';

const NUMBER_OF_COLUMNS = {
    [breakpoints.name.SMALL]: 4,
    [breakpoints.name.MEDIUM]: 6,
    [breakpoints.name.LARGE]: 12,
    [breakpoints.name.XLARGE]: 13,
};

const GridOverlay = ({ breakpoint }) => {
    const [isGridVisible, setIsGridVisible] = useState(false);

    const toggleGrid = useCallback(
        (e) => {
            const key = e.keyCode || e.charCode || 0;

            // ctrl + g
            if (e.ctrlKey && key === 71) {
                setIsGridVisible(!isGridVisible);
            }
        },
        [isGridVisible],
    );

    useEffect(() => {
        window.addEventListener('keydown', toggleGrid);

        return () => {
            window.removeEventListener('keydown', toggleGrid);
        };
    }, [toggleGrid]);

    const gridOverlayClasses = cx('grid-overlay', {
        'grid-overlay--visible': isGridVisible,
    });

    const renderColumns = () => {
        const columns = [];

        for (let i = 0; i < NUMBER_OF_COLUMNS[breakpoint]; i += 1) {
            columns.push(<GridCell key={i} sm={1} md={1} lg={1} xl={1} className="grid-overlay__column" />);
        }

        return columns;
    };

    return (
        <div className={gridOverlayClasses}>
            <Grid>
                {renderColumns()}
            </Grid>
        </div>
    );
};

GridOverlay.propTypes = {
    breakpoint: PropTypes.string,
};

GridOverlay.defaultProps = {
    breakpoint: 'small',
};

export default withWindow(GridOverlay);
