import React from "react";

import { AnimatePresence, motion } from "framer-motion";
import {
  faBehanceSquare,
  faInstagramSquare,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import styles from "./Contact.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DeviceSizes } from "../../constants/sizes";
import { useWindowSize } from "../../hooks/useWindowSize";


const Contact = () => {
  const [deviceWidth] = useWindowSize();
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 1 } }}
        exit={{ opacity: 0 }}
        className={styles.wrapper}
      >
        <div>
          <h1 className={styles.title}>Contact me</h1>
          <p>
            Thank you for visiting my website. If you would like to get in touch
            with me, you can find me on:
          </p>
          <p></p>
          <div className={styles.media}>
            <a
              className={styles.mediaLink}
              href="https://www.linkedin.com/in/predragtodorovic1/"
              target={deviceWidth > DeviceSizes.laptop ? "_blank" : "_self"}
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faLinkedinIn} />
              <p> @predragtodorovic1</p>
            </a>
            <a
              className={styles.mediaLink}
              href="https://www.instagram.com/predrag9/?hl=en"
              target={deviceWidth > DeviceSizes.laptop ? "_blank" : "_self"}
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faInstagramSquare} />
              <p>@predrag9</p>
            </a>
            <a
              className={styles.mediaLink}
              href="https://www.behance.net/predragt"
              target={deviceWidth > DeviceSizes.laptop ? "_blank" : "_self"}
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faBehanceSquare} />
              <p>@predragt</p>
            </a>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default Contact;
