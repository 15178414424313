import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import "./index.scss";

const Container = ({ children, className }) => {
  const containerClass = cx("container", {
    [className]: className,
  });

  return <div className={containerClass}>{children}</div>;
};

Container.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
};

Container.defaultProps = {
  className: "",
};

export default Container;
