import React from "react";
import styles from "./Footer.module.scss";

const Footer = () => {
  return (
    <footer className={styles.root}>
      <p className={styles.copyRight}>
        {" "}
        Copyright © {new Date().getFullYear()} Predrag Todorović.{" "}
      </p>
      <p> All rights reserved.</p>
    </footer>
  );
};

export default Footer;
