import React, { useContext, useEffect, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import cx from "classnames";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faBars } from "@fortawesome/free-solid-svg-icons";
import { useWindowSize } from "../../hooks/useWindowSize";

import { routes } from "../../constants/routes";
import { DeviceSizes } from "../../constants/sizes";

import pt from "../../assets/images/pt.png";
import { GridCell } from "../Grid";

import { motion, AnimatePresence } from "framer-motion";
import { useLockBodyScroll } from "../../hooks/useLockBodyScroll";

import { useScrollDirection } from "../../hooks/useScrollDirection";
import { ForwardRef } from "../ModalAside/AsideContent";
import { HeaderContext } from "../../App";
import Overlay from "../../Overlay/Overlay";

import styles from "./Header.module.scss";

const Header = ({ hide }) => {
  const { isOpenHeader, setIsOpenHeader } = useContext(HeaderContext);

  const navLinks = [
    { title: "PHOTOGRAPHY", to: routes.START_PAGE },
    { title: "VIDEOGRAPHY", to: routes.PROJECTS_PAGE },
    { title: "ABOUT", to: routes.ABOUT_PAGE },
    { title: "CONTACT", to: routes.CONTACT_PAGE },
  ];

  const iconTimes = (
    <FontAwesomeIcon icon={faTimes} style={{ color: "black" }} />
  );
  const iconBars = <FontAwesomeIcon icon={faBars} style={{ color: "black" }} />;

  const [deviceWidth] = useWindowSize();

  function resetMenu() {
    if (deviceWidth > DeviceSizes.laptop) {
      setIsOpenHeader(false);
    }
  }

  useEffect(() => {
    resetMenu();
  }, [deviceWidth]);

  useLockBodyScroll(isOpenHeader);

  const scrollDirection = useScrollDirection(20);

  const modalRef = useRef(null);
  const closeButtonRef = useRef(null);

  const onClose = () => {
    if (isOpenHeader) {
      setIsOpenHeader(false);
    }
  };

  const location = useLocation();

  const removeItem = () => {
    if (
      location.pathname === "/about" ||
      location.pathname === "/contact" ||
      location.pathname === "/videography"
    ) {
      localStorage.removeItem("scrollPosition");
    }
  };

  return (
    <div
      className={cx(styles.root, {
        [styles.down]: !isOpenHeader && scrollDirection === "down",
        [styles.up]: scrollDirection === "up",
        [styles.isHidden]: hide,
      })}
    >
      <nav>
        <GridCell sm={1} md={1} lg={1} xl={1}>
          <NavLink to={routes.START_PAGE} className={styles.logo}>
            <div className={styles.imageWrapper}>
              <img src={pt} alt="Pt logo" className={styles.image} />
            </div>
          </NavLink>
        </GridCell>
      </nav>

      <motion.button
        whileTap={{ scale: 0.9 }}
        className={styles.menuBtn}
        onClick={() => setIsOpenHeader((prev) => !prev)}
        ref={closeButtonRef}
        aria-label="Menu"
      >
        {isOpenHeader ? iconTimes : iconBars}
      </motion.button>

      {/* MENU */}
      {deviceWidth >= DeviceSizes.laptop ? (
        <div className={styles.menu}>
          <div></div>
          {navLinks.map(({ title, to }, i) => (
            <NavLink
              to={to}
              key={i}
              onClick={removeItem}
              className={styles.menuItem}
              end={to === routes.START_PAGE}
              style={({ isActive }) =>
                isActive
                  ? {
                      fontWeight: "bold",
                    }
                  : null
              }
            >
              {title}
            </NavLink>
          ))}
        </div>
      ) : (
        <AnimatePresence>
          {isOpenHeader && (
            <div style={{ height: "100%" }}>
              <Overlay onClick={onClose} />
              <ForwardRef
                modalRef={modalRef}
                isOpen={isOpenHeader}
                onClose={() => {
                  setIsOpenHeader(false);
                }}
                setIsOpenHeader={setIsOpenHeader}
              />
            </div>
          )}
        </AnimatePresence>
      )}
    </div>
  );
};

export default Header;
