import React, { Fragment, useEffect } from "react";
import { useParams } from "react-router-dom";
import { PT_SECTIONS_MOCK } from "../../mock/Photos.mock";
import cx from "classnames";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

import { DeviceSizes } from "../../constants/sizes";

import { useLockBodyScroll } from "../../hooks/useLockBodyScroll";

import { AnimatePresence, motion } from "framer-motion";
import { useWindowSize } from "../../hooks/useWindowSize";
import Card from "../Card";
import useModal from "../../hooks/useModal";

import styles from "./Gallery.module.scss";

const Gallery = () => {
  const params = useParams();
  const [deviceWidth] = useWindowSize();

  const data = PT_SECTIONS_MOCK.sections.find(
    (section) => section.type === params.id
  );

  const {
    handleOpenModal,
    handleCloseModal,
    nextSlide,
    prevSlide,
    modalOpen,
    slideNumber,
  } = useModal(data.gallery);

  useLockBodyScroll(modalOpen);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowLeft") {
        prevSlide();
      } else if (event.key === "ArrowRight") {
        nextSlide();
      } else if (event.key === "Escape") {
        handleCloseModal();
      }
    };
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [nextSlide, prevSlide, handleCloseModal]);

  return (
    <motion.div
      className={cx(styles.galleryRoot, { [styles.modalOpen]: modalOpen })}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
      exit={{ opacity: 0 }}

    >
      <AnimatePresence>
        {modalOpen && (
          <motion.div
            className={styles.sliderWrap}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            <button className={styles.btnClose} onClick={handleCloseModal}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <button className={styles.btnPrev} onClick={prevSlide}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>
            <button className={styles.btnNext} onClick={nextSlide}>
              <FontAwesomeIcon icon={faArrowRight} />
            </button>
            <div className={styles.fullScreenImage}>
              <img src={data.gallery[slideNumber].url} alt="" />
            </div>
            <h3 className={styles.galleryTitle}>
              {data.gallery[slideNumber].title}
            </h3>
          </motion.div>
        )}
      </AnimatePresence>

      <h1 className={styles.description}>{data.title}</h1>
      {data.description.map((desc, index) => (
        <h3 key={index} className={styles.description}>
          {desc}
        </h3>
      ))}
      <div className={styles.root}>
        {data.gallery.map((photo, index) =>
          deviceWidth > DeviceSizes.laptop ? (
            <Fragment key={index}>
              <Card
                wrapperType="button"
                image={{
                  src: photo.url,
                  alt: photo.alt,
                }}
                onClick={() => handleOpenModal(index)}
              />
            </Fragment>
          ) : (
            <Fragment key={index}>
              <Card
                image={{
                  src: photo.url,
                  alt: photo.alt,
                }}
              />
            </Fragment>
          )
        )}
      </div>
    </motion.div>
  );
};

export default Gallery;
