import React from "react";

import styles from "./Overlay.module.scss";
import { motion } from "framer-motion";

const Overlay = ({ onClick }) => {
  return (
    <motion.div
      onClick={onClick}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { delay: 0.5, duration: 0.5 } }}
      transition={{ duration: 0.5 }}
      className={styles.overlay}
    ></motion.div>
  );
};

export default Overlay;
