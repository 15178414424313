import { useEffect } from 'react';

export const useLockBodyScroll = (isOpenModal)=> {
    useEffect(() => {
        const body = document.documentElement;
        const classAction = isOpenModal ? 'add' : 'remove';

        body.classList[classAction]('body__locked');
    }, [isOpenModal]);
};
