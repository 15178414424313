import { useEffect, useState } from "react";


export const useScrollDirection = (threshold = 0) => {
  const [scrollDir, setScrollDir] = useState("up");

  useEffect(() => {
    let lastScrollY = window.pageYOffset;
    let ticking = false;

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }
      const direction = scrollY > lastScrollY ? "down" : "up";
      setScrollDir(direction);
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const handleScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDirection);
        ticking = true;
      }
    };

    window.addEventListener("scroll", handleScroll,  { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [scrollDir, threshold]);

  return scrollDir;
};
