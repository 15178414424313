import beleSkele from "../assets/images/APlaceToBe/01_Bele Skale, Slovenia.jpg";
import bled from "../assets/images/APlaceToBe/Bled, Slovenia.jpg";
import brijuni from "../assets/images/APlaceToBe/Brijuni, Croatia.jpg";
import corfy from "../assets/images/APlaceToBe/Corfu, Greece.jpg";
import cityOfCorfy from "../assets/images/APlaceToBe/City of Corfu, Greece.jpg";
import fehraltorf from "../assets/images/APlaceToBe/Fehraltorf, Switzerland.jpg";
import golija from "../assets/images/APlaceToBe/Golija, Serbia.JPG";
import grza from "../assets/images/APlaceToBe/Grza, Serbia.JPG";
import jozaniForest from "../assets/images/APlaceToBe/Jozani forest, Zanzibar.jpg";
import kibyra from "../assets/images/APlaceToBe/Kibyra, Turkey.jpg";
import kopaonik from "../assets/images/APlaceToBe/Kopaonik, Serbia.jpg";
import mellieha from "../assets/images/APlaceToBe/Mellieha, Malta.jpg";
import noviSad from "../assets/images/APlaceToBe/Novi Sad, Serbia.jpg";
import rome from "../assets/images/APlaceToBe/Rome, Italy.jpg";
import rovinj from "../assets/images/APlaceToBe/Rovinj, Croatia.jpg";
import skoplje from "../assets/images/APlaceToBe/Skopje, Macedonia.jpg";
import sokolica from "../assets/images/APlaceToBe/Sokolica, Serbia.JPG";
import stariBanovci from "../assets/images/APlaceToBe/Stari Banovci, Serbia.jpg";
import stoneTown from "../assets/images/APlaceToBe/Stonetown, Zanzibar.jpg";
import pinawalla from "../assets/images/dodatak za pt/A place to be/Pinawalla - Sri Lanka.jpg";
import sigiryia from "../assets/images/dodatak za pt/A place to be/Sigiryia - Sri Lanka.jpg";

import emotion1 from "../assets/images/EmotionOfImprovisation/Emotion Of Improvisation 1.jpg";
import emotion2 from "../assets/images/EmotionOfImprovisation/Emotion Of Improvisation 2.jpg";
import emotion3 from "../assets/images/EmotionOfImprovisation/Emotion Of Improvisation 3.jpg";
import emotion4 from "../assets/images/EmotionOfImprovisation/Emotion Of Improvisation 4.jpg";
import emotion5 from "../assets/images/EmotionOfImprovisation/Emotion Of Improvisation 5.jpg";
import emotion6 from "../assets/images/EmotionOfImprovisation/Emotion Of Improvisation 6.jpg";
import emotion7 from "../assets/images/EmotionOfImprovisation/Emotion Of Improvisation 7.jpg";
import emotion8 from "../assets/images/EmotionOfImprovisation/01_motion Improvisation 8.jpg";
import emotion9 from "../assets/images/EmotionOfImprovisation/Emotion Of Improvisation 9.jpg";
import emotion10 from "../assets/images/EmotionOfImprovisation/Emotion Of Improvisation 10.jpg";
import emotion11 from "../assets/images/EmotionOfImprovisation/Emotion Of Improvisation 11.jpg";
import emotion12 from "../assets/images/EmotionOfImprovisation/Emotion Of Improvisation 12.jpg";
import emotion13 from "../assets/images/EmotionOfImprovisation/Emotion Of Improvisation 13.jpg";
import emotion14 from "../assets/images/EmotionOfImprovisation/Emotion Of Improvisation 14.jpg";
import emotion15 from "../assets/images/EmotionOfImprovisation/Emotion Of Improvisation 15.jpg";
import emotion16 from "../assets/images/EmotionOfImprovisation/Emotion Of Improvisation 16.jpg";
import emotion17 from "../assets/images/EmotionOfImprovisation/Emotion Of Improvisation 17.jpg";
import emotion18 from "../assets/images/EmotionOfImprovisation/Emotion Of Improvisation 18.jpg";
import emotion19 from "../assets/images/EmotionOfImprovisation/Emotion Of Improvisation 19.jpg";
import emotion20 from "../assets/images/EmotionOfImprovisation/Emotion Of Improvisation 20.jpg";

import introspection from "../assets/images/Introspection/Introspection1.jpg";
import introspection2 from "../assets/images/Introspection/Introspection2.jpg";
import introspection3 from "../assets/images/Introspection/Introspection3.jpg";
import introspection4 from "../assets/images/Introspection/Introspection4.jpg";
import introspection5 from "../assets/images/Introspection/Introspection5.jpg";
import introspection6 from "../assets/images/Introspection/Introspection6.jpg";
import introspection7 from "../assets/images/Introspection/Introspection7.jpg";
import introspection8 from "../assets/images/Introspection/Introspection8.jpg";
import introspection9 from "../assets/images/Introspection/Introspection9.jpg";
import introspection10 from "../assets/images/Introspection/Introspection10.jpg";

import foolForYourLove from "../assets/images/MusicCovers/first.jpg";
import stop from "../assets/images/MusicCovers/stop.jpg";
import cantStop from "../assets/images/MusicCovers/cantStop.jpg";
import now from "../assets/images/MusicCovers/now.jpg";
import letItOut from "../assets/images/MusicCovers/letItOut.jpg";
import perfectCrime from "../assets/images/MusicCovers/perfectCrime.jpg";
import nextToYou from "../assets/images/MusicCovers/nextToYou.jpg";
import giveItAll from "../assets/images/MusicCovers/giveItAll.jpg";
import pinkFloyd from "../assets/images/MusicCovers/pinkFloyd.jpg";
import kisobran2016 from "../assets/images/MusicCovers/kisobran2016.jpg";
import drugstore from "../assets/images/MusicCovers/drugstore.jpg";
import quietTime from "../assets/images/MusicCovers/quiteTime.jpg";

import quarantine from "../assets/images/MusicCovers/quarantine.jpg";
import reality from "../assets/images/MusicCovers/reality.jpg";
import pig from "../assets/images/MusicCovers/pig.jpg";
import doggo from "../assets/images/MusicCovers/doggo.jpg";
import exibiton from "../assets/images/MusicCovers/exhibition.jpeg";
import mock1 from "../assets/images/MusicCovers/18.jpeg";
import mock2 from "../assets/images/MusicCovers/19.jpeg";
import mock3 from "../assets/images/MusicCovers/20.jpeg";

import diptych1 from "../assets/images/diptych/Cant Stop Thinking About You.jpg";
import diptych2 from "../assets/images/diptych/dark resort.jpg";
import diptych3 from "../assets/images/diptych/Isla de Tabarca.jpg";
import diptych4 from "../assets/images/diptych/John The Conqueror.jpg";
import diptych5 from "../assets/images/diptych/Kat.jpg";
import diptych6 from "../assets/images/diptych/Legg_CATL.jpg";
import diptych7 from "../assets/images/diptych/long hours.jpg";
import diptychCover from "../assets/images/diptych/diptychCover.jpeg";
import diptych8 from "../assets/images/dodatak za pt/diptych/Kandy - Sri Lanka.jpg";

import portrait1 from "../assets/images/Portraits/01_Dev9t.jpg";
import portrait2 from "../assets/images/Portraits/Ana Popovic.jpg";
import portrait3 from "../assets/images/Portraits/Danilo Borovcanin.jpg";
import portrait4 from "../assets/images/Portraits/Fluke T II.jpg";
import portrait5 from "../assets/images/Portraits/Fluke T.jpg";
import portrait6 from "../assets/images/Portraits/Jelena T.jpg";
import portrait7 from "../assets/images/Portraits/Jelena.jpg";
import portrait8 from "../assets/images/Portraits/Jerusalem.jpg";
import portrait9 from "../assets/images/Portraits/Love On The Dance Floor.jpg";
import portrait10 from "../assets/images/Portraits/Merima.jpg";
import portrait11 from "../assets/images/Portraits/Nenad T.jpg";
import portrait12 from "../assets/images/Portraits/Nenad.jpg";
import portrait13 from "../assets/images/Portraits/Orange seller.jpg";
import portrait14 from "../assets/images/Portraits/Raphael Wressnig.jpg";
import portrait15 from "../assets/images/Portraits/rehearsal.jpg";
import portrait16 from "../assets/images/Portraits/The Cockblockers I.jpg";
import portrait17 from "../assets/images/Portraits/The Cockblockers II.jpg";
import portrait18 from "../assets/images/Portraits/The Cockblockers III.jpg";
import portrait19 from "../assets/images/Portraits/wildling.jpg";
import portrait20 from "../assets/images/Portraits/Fool For Your Love - BTS.jpg";
import portrait21 from "../assets/images/Portraits/Katarina - Día de Muertos.jpg";

import scrapbook1 from "../assets/images/Scrapbook/Any Color You Like.jpg";
import scrapbook2 from "../assets/images/Scrapbook/Belgrade I.jpg";
import scrapbook3 from "../assets/images/Scrapbook/Butterfly man.jpg";
import scrapbook4 from "../assets/images/Scrapbook/Cocktail.jpg";
import scrapbook5 from "../assets/images/Scrapbook/college days.JPG";
import scrapbook6 from "../assets/images/Scrapbook/hanging up.jpg";
import scrapbook7 from "../assets/images/Scrapbook/Hilton.jpg";
import scrapbook8 from "../assets/images/Scrapbook/I dont think you are ready for this jelly.jpg";
import scrapbook9 from "../assets/images/Scrapbook/Marriot.jpg";
import scrapbook10 from "../assets/images/Scrapbook/Mili The Dog.jpg";
import scrapbook11 from "../assets/images/Scrapbook/Munich.jpg";
import scrapbook12 from "../assets/images/Scrapbook/Porsch in Rabat, Malta.jpg";
import scrapbook13 from "../assets/images/Scrapbook/Prokop.jpg";
import scrapbook14 from "../assets/images/Scrapbook/public reflection.jpg";
import scrapbook15 from "../assets/images/Scrapbook/Rome II.jpg";
import scrapbook16 from "../assets/images/Scrapbook/Rome.jpg";
import scrapbook17 from "../assets/images/Scrapbook/Smederevo.jpg";
import scrapbook18 from "../assets/images/Scrapbook/spicy.jpg";
import scrapbook19 from "../assets/images/Scrapbook/Tour de Zanzibar I.jpg";
import scrapbook20 from "../assets/images/Scrapbook/Tour de Zanzibar II.jpg";
import scrapbook21 from "../assets/images/Scrapbook/Tour de Zanzibar III.jpg";
import scrapbook22 from "../assets/images/Scrapbook/Watchers, Imtarfa, Malta.jpg";
import scrapbook23 from "../assets/images/Scrapbook/Zurich.jpg";
import scrapbook24 from "../assets/images/dodatak za pt/Scrapbook/DPT_6464.jpg";
import scrapbook25 from "../assets/images/dodatak za pt/Scrapbook/Nuwara Eliya - Sri Lanka.jpg";

import letItOut1 from "../assets/images/Let It Out/01_Let It Out_artwork 9.jpg";
import letItOut2 from "../assets/images/Let It Out/Let It Out_artwork 1.jpg";
import letItOut3 from "../assets/images/Let It Out/Let It Out_artwork 2.jpg";
import letItOut4 from "../assets/images/Let It Out/Let It Out_artwork 3.jpg";
import letItOut5 from "../assets/images/Let It Out/Let It Out_artwork 4.jpg";
import letItOut6 from "../assets/images/Let It Out/Let It Out_artwork 5.jpg";
import letItOut7 from "../assets/images/Let It Out/Let It Out_artwork 6.jpg";
import letItOut8 from "../assets/images/Let It Out/Let It Out_artwork 7.jpg";
import letItOut9 from "../assets/images/Let It Out/Let It Out_artwork 8.jpg";
import letItOut10 from "../assets/images/Let It Out/Let It Out_artwork 10.jpg";
import letItOut11 from "../assets/images/Let It Out/TC_album artwork I.jpg";
import letItOut12 from "../assets/images/Let It Out/TC_album artwork II.jpg";
import letItOut13 from "../assets/images/Let It Out/TC_album artwork III.jpg";
import letItOut14 from "../assets/images/Let It Out/TC_album artwork IV.jpg";

import irvingPennTribute1 from "../assets/images/Irving Penn Tribute/01_Tribute to Irving Penn 4.jpg";
import irvingPennTribute2 from "../assets/images/Irving Penn Tribute/Tribute to Irving Penn 1.jpg";
import irvingPennTribute3 from "../assets/images/Irving Penn Tribute/Tribute to Irving Penn 2.jpg";
import irvingPennTribute4 from "../assets/images/Irving Penn Tribute/Tribute to Irving Penn 3.jpg";
import irvingPennTribute5 from "../assets/images/Irving Penn Tribute/Tribute to Irving Penn 5.jpg";
import irvingPennTribute6 from "../assets/images/Irving Penn Tribute/Tribute to Irving Penn 6.jpg";
import irvingPennTribute7 from "../assets/images/Irving Penn Tribute/Tribute to Irving Penn 7.jpg";
import irvingPennTribute8 from "../assets/images/Irving Penn Tribute/Tribute to Irving Penn 8.jpg";
import irvingPennTribute9 from "../assets/images/Irving Penn Tribute/Tribute to Irving Penn 9.jpg";
import irvingPennTribute10 from "../assets/images/Irving Penn Tribute/Tribute to Irving Penn 10.jpg";

export const PT_SECTIONS_MOCK = {
  videography: [
    {
      title: "ALL ABOUT MUSIC",
      description: [
        "Music is my big passion! Although I'm not playing any instrument, I always see music throught images.",
        "These are some of my works related to music, from official music videos for performers and bands like Fluke T and The Cockblockers, to some live filmed acts and fan made videos.",
      ],
      covers: [
        {
          cover: foolForYourLove,
        },
        {
          cover: cantStop,
        },
        {
          cover: stop,
        },
        {
          cover: now,
        },
        {
          cover: letItOut,
        },
        {
          cover: perfectCrime,
        },
        {
          cover: nextToYou,
        },
        {
          cover: giveItAll,
        },
        {
          cover: pinkFloyd,
        },
        {
          cover: kisobran2016,
        },
        {
          cover: drugstore,
        },
        {
          cover: quietTime,
        },
      ],
      videos: [
        {
          href: "https://www.youtube.com/embed/u6CBwXEHnIw",
        },
        {
          href: "https://www.youtube.com/embed/jTqt4EVpemk",
        },
        {
          href: "https://www.youtube.com/embed/tdowSi-V94w",
        },
        {
          href: "https://www.youtube.com/embed/LgO2jZzvZoc",
        },
        {
          href: "https://www.youtube.com/embed/wa0vEO7Q51E",
        },
        {
          href: "https://www.youtube.com/embed/TYOGJA7hIO4",
        },
        {
          href: "https://www.youtube.com/embed/nCt0E9Xe9gA",
        },
        {
          href: "https://www.youtube.com/embed/rSR_DOE59z4",
        },
        {
          href: "https://player.vimeo.com/video/153569040?h=97f6853f4a",
        },
        {
          href: "https://player.vimeo.com/video/211001098?h=b1ad6b0fcc",
        },
        {
          href: "https://player.vimeo.com/video/211002696?h=ec15074ace",
        },
        {
          href: "https://player.vimeo.com/video/59598497?h=e41e3fd6c1",
        },
      ],
    },
    {
      title: "VIDEO ART",
      description: [
        "Videos created for different art projects. Some of them are student works, and some are documents of exhibitions.",
        "From short featured forms through documents to even some sort of animation.",
      ],
      covers: [
        {
          cover: exibiton,
        },
        {
          cover: mock1,
        },
        {
          cover: mock2,
        },
        {
          cover: mock3,
        },
        {
          cover: quarantine,
        },
        {
          cover: reality,
        },
        {
          cover: pig,
        },
        {
          cover: doggo,
        },
      ],

      videos: [
        {
          href: "https://player.vimeo.com/video/213513150?h=55360b878b",
        },
        {
          href: "https://player.vimeo.com/video/59373445?h=cebd15d92d",
        },
        {
          href: "https://player.vimeo.com/video/59365247?h=2c8c53febc",
        },
        {
          href: "https://player.vimeo.com/video/117829303?h=8fe8fad60a",
        },
        {
          href: "https://www.youtube.com/embed/UxUIVjiQpjA",
        },
        {
          href: "https://www.youtube.com/embed/fceeCv-h0H0",
        },
        {
          href: "https://www.youtube.com/embed/J8rcwmlUkUo",
        },
        {
          href: "https://www.youtube.com/embed/ttbctI8m9fs",
        },
      ],
    },
  ],
  sections: [
    {
      id: 1,
      title: "A place to be",
      type: "a-place-to-be",
      description: [
        "As someone who loves to travel, here are some snaps of the places that took my breath away in oneway or the other.",
        " Always in a search for a quiet place, forever filling the folders with travel photographs, this one will get bigger and bigger.",
      ],
      cover: beleSkele,
      gallery: [
        {
          id: 1,
          title: "Bele Skale, Slovenia",
          url: beleSkele,
        },
        {
          id: 2,
          title: "Bled, Slovenia",
          url: bled,
        },
        {
          id: 3,
          title: "Brijuni, Croatia",
          url: brijuni,
        },
        {
          id: 4,
          title: "City of Corfu, Greece",
          url: cityOfCorfy,
        },
        {
          id: 5,
          title: "Corfu, Greece",
          url: corfy,
        },
        {
          id: 6,
          title: "Fehraltorf, Switzerland",
          url: fehraltorf,
        },
        {
          id: 7,
          title: "Golija, Serbia",
          url: golija,
        },
        {
          id: 8,
          title: "Grza, Serbia",
          url: grza,
        },
        {
          id: 9,
          title: "Jozani forest, Zanzibar",
          url: jozaniForest,
        },
        {
          id: 10,
          title: "Kibyra, Turkey",
          url: kibyra,
        },
        {
          id: 11,
          title: "Kopaonik, Serbia",
          url: kopaonik,
        },
        {
          id: 12,
          title: "Mellieha, Malta",
          url: mellieha,
        },
        {
          id: 13,
          title: "Novi Sad, Serbia",
          url: noviSad,
        },
        {
          id: 14,
          title: "Rome, Italy",
          url: rome,
        },
        {
          id: 15,
          title: "Rovinj, Croatia",
          url: rovinj,
        },
        {
          id: 16,
          title: "Skopje, Macedonia",
          url: skoplje,
        },
        {
          id: 17,
          title: "Sokolica, Serbia",
          url: sokolica,
        },
        {
          id: 18,
          title: "Stari Banovci, Serbia",
          url: stariBanovci,
        },
        {
          id: 19,
          title: "Stonetown, Zanzibar",
          url: stoneTown,
        },
        {
          id: 20,
          title: "Pinawalla - Sri Lanka",
          url: pinawalla,
        },
        {
          id: 21,
          title: "Sigiryia - Sri Lanka",
          url: sigiryia,
        },
      ],
    },
    {
      id: 2,
      title: "Emotion of improvisation",
      type: "emotion-of-improvisation",
      description: [
        "Jazz - Emotion of improvisation is a project that was created continuously for 4 years (2013-2016).",
        "The set of black and white photographs emphasise emotion expressed both on large festival stages and in small smoky darkened clubs.",
        "Wanting to accentuate the notion of improvisation in jazz, this work also presents a certain form that deviates from classic concert photography.",
        "For this reason, the identity of the musician is in the background, while the moves, emotion and atmosphere are something that carries each recorded moment.",
      ],
      cover: emotion8,
      gallery: [
        {
          id: 1,
          url: emotion8,
        },
        {
          id: 2,
          url: emotion1,
        },
        {
          id: 3,
          url: emotion2,
        },
        {
          id: 4,
          url: emotion3,
        },
        {
          id: 5,
          url: emotion4,
        },
        {
          id: 6,
          url: emotion5,
        },
        {
          id: 7,
          url: emotion6,
        },
        {
          id: 8,
          url: emotion7,
        },
        {
          id: 9,
          url: emotion8,
        },
        {
          id: 10,
          url: emotion9,
        },
        {
          id: 11,
          url: emotion10,
        },
        {
          id: 12,
          url: emotion11,
        },
        {
          id: 13,
          url: emotion12,
        },
        {
          id: 14,
          url: emotion13,
        },
        {
          id: 15,
          url: emotion14,
        },
        {
          id: 16,
          url: emotion15,
        },
        {
          id: 17,
          url: emotion16,
        },
        {
          id: 18,
          url: emotion17,
        },
        {
          id: 19,
          url: emotion18,
        },
        {
          id: 20,
          url: emotion19,
        },
        {
          id: 21,
          url: emotion20,
        },
      ],
    },
    {
      id: 3,
      title: "Diptych",
      type: "diptych",
      description: ["Various photographs that for me make 2in1 concept."],
      cover: diptychCover,
      gallery: [
        {
          id: 1,
          title: "Cant Stop Thinking About You",
          url: diptych1,
        },
        {
          id: 2,
          title: "Dark resort",
          url: diptych2,
        },
        {
          id: 3,
          title: "Isla de Tabarca",
          url: diptych3,
        },
        {
          id: 4,
          title: "John The Conqueror",
          url: diptych4,
        },
        {
          id: 5,
          title: "Kat",
          url: diptych5,
        },
        {
          id: 6,
          title: "Legg_CATL",
          url: diptych6,
        },
        {
          id: 7,
          title: "Long hour",
          url: diptych7,
        },
        {
          id: 8,
          title: "Kandy - Sri Lanka",
          url: diptych8,
        },
      ],
    },
    {
      id: 4,
      title: "Portraits",
      type: "portraits",
      description: [
        "Different people, different places, different circumstances.",
      ],
      cover: portrait20,
      gallery: [
        {
          id: 1,
          title: "Dev9t",
          url: portrait1,
        },
        {
          id: 2,
          title: "Ana Popovic",
          url: portrait2,
        },
        {
          id: 3,
          title: "Danilo Borovcanin",
          url: portrait3,
        },
        {
          id: 4,
          title: "Fluke T",
          url: portrait4,
        },
        {
          id: 5,
          title: "Fluke T II",
          url: portrait5,
        },
        {
          id: 6,
          title: "Jelena",
          url: portrait6,
        },
        {
          id: 7,
          title: "Jelena II",
          url: portrait7,
        },
        {
          id: 8,
          title: "Jerusalem",
          url: portrait8,
        },
        {
          id: 9,
          title: "Love on the dance floor",
          url: portrait9,
        },
        {
          id: 10,
          title: "Merima",
          url: portrait10,
        },
        {
          id: 11,
          title: "Nenad T",
          url: portrait11,
        },
        {
          id: 12,
          title: "Nenad",
          url: portrait12,
        },
        {
          id: 13,
          title: "Orange seller",
          url: portrait13,
        },
        {
          id: 14,
          title: "Raphael Wressnig",
          url: portrait14,
        },
        {
          id: 15,
          title: "Rehearsal",
          url: portrait15,
        },
        {
          id: 16,
          title: "The Cockblockers",
          url: portrait16,
        },
        {
          id: 17,
          title: "The Cockblockers II",
          url: portrait17,
        },
        {
          id: 18,
          title: "The Cockblockers III",
          url: portrait18,
        },
        {
          id: 19,
          title: "Wildling",
          url: portrait19,
        },
        {
          id: 20,
          title: "Fool for your love - BTS",
          url: portrait20,
        },
        {
          id: 21,
          title: "Katarina - Día de Muertos",
          url: portrait21,
        },
      ],
    },
    {
      id: 5,
      title: "Scrapbook",
      type: "scrapbook",
      description: [
        "All those photos that were created during the years, but don’t fit well in any other gallery make a scrapbook, a set of memories or perhaps inspiration for some further work.",
      ],
      cover: scrapbook21,
      gallery: [
        {
          id: 1,
          title: "Any Color You Like",
          url: scrapbook1,
        },
        {
          id: 2,
          title: "Belgrade",
          url: scrapbook2,
        },
        {
          id: 3,
          title: "Butterfly man",
          url: scrapbook3,
        },
        {
          id: 4,
          title: "Cocktail",
          url: scrapbook4,
        },
        {
          id: 5,
          title: "College days",
          url: scrapbook5,
        },
        {
          id: 6,
          title: "Hanging up",
          url: scrapbook6,
        },
        {
          id: 7,
          title: "Hilton",
          url: scrapbook7,
        },
        {
          id: 8,
          title: "I dont think you are ready for this jelly",
          url: scrapbook8,
        },
        {
          id: 9,
          title: "Marriot",
          url: scrapbook9,
        },
        {
          id: 10,
          title: "Mili The Dog",
          url: scrapbook10,
        },
        {
          id: 11,
          title: "Munich",
          url: scrapbook11,
        },
        {
          id: 12,
          title: "Porsch in Rabat, Malta",
          url: scrapbook12,
        },
        {
          id: 13,
          title: "Prokop",
          url: scrapbook13,
        },
        {
          id: 14,
          title: "Public reflection",
          url: scrapbook14,
        },
        {
          id: 15,
          title: "Rome II",
          url: scrapbook15,
        },
        {
          id: 16,
          title: "Rome",
          url: scrapbook16,
        },
        {
          id: 17,
          title: "Smederevo",
          url: scrapbook17,
        },
        {
          id: 18,
          title: "Spicy",
          url: scrapbook18,
        },
        {
          id: 19,
          title: "Tour de Zanzibar I",
          url: scrapbook19,
        },
        {
          id: 20,
          title: "Tour de Zanzibar II",
          url: scrapbook20,
        },
        {
          id: 21,
          title: "Tour de Zanzibar III",
          url: scrapbook21,
        },
        {
          id: 22,
          title: "Watchers, Imtarfa - Malta",
          url: scrapbook22,
        },
        {
          id: 23,
          title: "Zurich",
          url: scrapbook23,
        },
        {
          id: 24,
          title: "Sigiriya climber",
          url: scrapbook24,
        },
        {
          id: 25,
          title: "Nuwara Eliya - Sri Lanka",
          url: scrapbook25,
        },
      ],
    },
    {
      id: 6,
      title: "Let it out",
      type: "let-it-out",
      description: [
        '"Let It Out" is a commissioned work for the rock band The Cockblockers.',
        "Painting glass with acrylic paint and breaking it many times over and over was the direction in which I went.",
        "Through a style and technique that is familiar, I considered that unique way of breaking glass and color spill will bring something new.",
        "Here are chosen photos from that session which turned out to be album cover and artwork inside of the CD package. At the end you can see the final outcome.",
      ],
      cover: letItOut1,
      gallery: [
        {
          id: 1,
          url: letItOut1,
        },
        {
          id: 2,
          url: letItOut2,
        },
        {
          id: 3,
          url: letItOut3,
        },
        {
          id: 4,
          url: letItOut4,
        },
        {
          id: 5,
          url: letItOut5,
        },
        {
          id: 6,
          url: letItOut6,
        },
        {
          id: 7,
          url: letItOut7,
        },
        {
          id: 8,
          url: letItOut8,
        },
        {
          id: 9,
          url: letItOut9,
        },
        {
          id: 10,
          url: letItOut10,
        },
        {
          id: 11,
          url: letItOut11,
        },
        {
          id: 12,
          url: letItOut12,
        },
        {
          id: 13,
          url: letItOut13,
        },
        {
          id: 14,
          url: letItOut14,
        },
      ],
    },
    {
      id: 7,
      title: "Irving Penn Tribute",
      description: [
        '"Every so often what lies behind the facade is rare and more wonderful than the subject knows or dares to believe." - Irving Pennn',
        "Portraits in manner of Irving Penn. (2014)",
      ],
      type: "irving-penn-tribute",
      cover: irvingPennTribute5,
      gallery: [
        {
          id: 1,
          url: irvingPennTribute1,
        },
        {
          id: 2,
          url: irvingPennTribute2,
        },
        {
          id: 3,
          url: irvingPennTribute3,
        },
        {
          id: 4,
          url: irvingPennTribute4,
        },
        {
          id: 5,
          url: irvingPennTribute5,
        },
        {
          id: 6,
          url: irvingPennTribute6,
        },
        {
          id: 7,
          url: irvingPennTribute7,
        },
        {
          id: 8,
          url: irvingPennTribute8,
        },
        {
          id: 9,
          url: irvingPennTribute9,
        },
        {
          id: 10,
          url: irvingPennTribute10,
        },
      ],
    },
    {
      id: 8,
      title: "Introspection",
      type: "introspection",
      description: [
        "ɪntrə(ʊ)ˈspɛkʃ(ə)n",
        "noun: introspection; plural noun: introspections",
        "Introspection is the examination of one's own conscious thoughts and feelings.",
        "Series of 10 anaglyph photographs (technique used with old school 3D glasses). (2015)",
        // '"',
      ],
      cover: introspection9,
      gallery: [
        {
          id: 1,
          url: introspection,
        },
        {
          id: 2,
          url: introspection2,
        },
        {
          id: 3,
          url: introspection3,
        },
        {
          id: 4,
          url: introspection4,
        },
        {
          id: 5,
          url: introspection5,
        },
        {
          id: 6,
          url: introspection6,
        },
        {
          id: 7,
          url: introspection7,
        },
        {
          id: 8,
          url: introspection8,
        },
        {
          id: 9,
          url: introspection9,
        },
        {
          id: 10,
          url: introspection10,
        },
      ],
    },
  ],
};
