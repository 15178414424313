import React, { Fragment, useContext, useState } from "react";

import { AnimatePresence, motion } from "framer-motion";

import { PT_SECTIONS_MOCK } from "../../mock/Photos.mock";
import { DeviceSizes } from "../../constants/sizes";
import Card from "../Card";
import { useLockBodyScroll } from "../../hooks/useLockBodyScroll";
import { useWindowSize } from "../../hooks/useWindowSize";

import styles from "./Videography.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { ModalContext } from "../Modal/Modal.context";

const Videography = () => {
  const data = PT_SECTIONS_MOCK.videography;

  const [selectedVideo, setSelectedVideo] = useState({
    sectionIndex: null,
    videoIndex: null,
  });

  const { modalOpen, setModalOpen } = useContext(ModalContext);

  const handleVideoSelect = (sectionIndex, videoIndex) => {
    setSelectedVideo({ sectionIndex, videoIndex });
    setModalOpen(true);
  };

  useLockBodyScroll(modalOpen);
  // useLockBodyScroll(selectedVideo.sectionIndex !== null);

  const [deviceWidth] = useWindowSize();

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
      exit={{ opacity: 0 }}
      className={modalOpen ? styles.modalOpen : ""}
    >
      {data.map((section, sectionIndex) => (
        <Fragment key={sectionIndex}>
          <div>
            <h2 className={styles.space}>{section.title}</h2>
            {section.description.map((desc, index) => (
              <h3 className={styles.space} key={index}>
                {desc}
              </h3>
            ))}
            <div className={styles.root}>
              {section.covers.map((video, videoIndex) =>
                deviceWidth > DeviceSizes.laptop ? (
                  <Fragment key={videoIndex}>
                    <Card
                      isVideo
                      wrapperType="button"
                      image={{
                        src: video.cover,
                        alt: "Cover photo",
                      }}
                      classNames={styles.videoWrapper}
                      onClick={() =>
                        handleVideoSelect(sectionIndex, videoIndex)
                      }
                    />
                  </Fragment>
                ) : (
                  <Fragment key={videoIndex}>
                    <Card
                      isVideo
                      wrapperType="link"
                      classNames={styles.videoWrapper}
                      image={{
                        src: video.cover,
                        alt: "Cover photo",
                      }}
                      href={section.videos[videoIndex].href}
                    />
                  </Fragment>
                )
              )}
            </div>
            <AnimatePresence>
              {modalOpen && (
                <motion.div
                  className={styles.sliderWrap}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                >
                  <button
                    className={styles.btnClose}
                    onClick={handleModalClose}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                  <div className={styles.fullScreenImage}>
                    <iframe
                      className={styles.video}
                      width="80%"
                      height="80%"
                      src={
                        data[selectedVideo.sectionIndex].videos[
                          selectedVideo.videoIndex
                        ].href
                      }
                      title="Video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    ></iframe>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </Fragment>
      ))}
    </motion.div>
  );
};

export default Videography;
