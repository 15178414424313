import React from "react";
import { Link } from "react-router-dom";
import cx from "classnames";

import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useWindowSize } from "../../hooks/useWindowSize";
import { DeviceSizes } from "../../constants/sizes";

import styles from "./Card.module.scss";

const Card = ({
  title,
  description,
  wrapperType,
  onClick,
  href,
  image,
  url,
  isVideo,
  classNames,
}) => {
  const [deviceWidth] = useWindowSize();

  let Wrapper;
  if (deviceWidth <= DeviceSizes.laptop && isVideo) {
    Wrapper = (props) => <a {...props} />;
  } else {
    switch (wrapperType) {
      case "link":
        Wrapper = Link;
        break;
      case "button":
        Wrapper = "button";
        break;
      default:
        Wrapper = "div";
        break;
    }
  }

  return (
    <Wrapper
      rel="preload"
      onClick={onClick}
      className={cx(styles.image, classNames)}
      href={href}
      to={href}
    >
      {!url ? (
        <>
          {isVideo ? (
            <>
              <img src={image.src} alt={image.alt} height="100%" width="100%" />
              <div className={styles.videoButton}>
                <FontAwesomeIcon icon={faPlay} color="white" />
              </div>
            </>
          ) : (
            <img src={image.src} alt={image.alt} height="100%" width="100%" />
          )}
          {title && <h3 className={styles.title}>{title}</h3>}
          {description && <p>{description}</p>}
        </>
      ) : (
        <iframe
          className={styles.video}
          width="560"
          height="315"
          src={url}
          title="Video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      )}
    </Wrapper>
  );
};

export default Card;
